<script>
import {defineComponent} from 'vue'
import TranslationInput from "../../elements/translation-input.vue";
import {mapGetters} from "vuex";

export default defineComponent({
    name: "task-type-form",

    emits: ['saved'],

    props: {
        taskType: {
            type: Object,
            required: true
        }
    },

    components: {TranslationInput},

    computed: {
        ...mapGetters({
            'loading': 'taskType/loading',
            'states': 'taskType/states',
            'errors': 'taskType/errors'
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },
    },

    data: function () {
        return {
            model: {}
        }
    },

    methods: {

        save: function () {
            const method = this.model.id ? 'update' : 'create'

            const data = Object.assign({}, this.model)

            return this.$store.dispatch(`taskType/${method}`, data).then(saved => {
                this.setModel(saved)
                this.$emit('saved')
                if (method === 'create') {
                    this.$router.push(this.$t('routes.'+'/master-data/tasks/types/:id(\\d+)', {id: this.model.id}).rmValidation())
                }
            })
        },

        setModel: function (model = {}) {
            this.model = Object.assign({}, JSON.parse(JSON.stringify(model)))
        },
    },

    watch: {
        taskType: function (value) {
            this.setModel(value)
        }
    },

    created() {
        this.setModel(this.taskType)
    }
})
</script>

<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div class="col-lg-3">
                    <label
                        :class="{'text-danger': states.name === false}"
                        class="form-label"
                        for="name"
                    >{{ $t('tasks::types.columns.name').ucFirst() }} <span class="text-primary">*</span></label
                    >
                    <input
                        id="name"
                        v-model="model.name"
                        :class="{'border-danger': states.name === false}"
                        :placeholder="$t('tasks::types.placeholders.name').ucFirst()"
                        class="form-control"
                        name="name"
                        type="text"
                    />
                    <div :class="{'d-block': states.name === false}" class="invalid-feedback">{{ errors.name }}</div>
                </div>

                <div class="col-lg-3">
                    <label
                        :class="{'text-danger': states.translation_key === false}"
                        class="form-label"
                        for="translation_key"
                    >{{ $t('tasks::types.columns.translation_key').ucFirst() }}</label
                    >
                    <translation-input
                        v-model="model.translation_key"
                        :error="errors.translation_key"
                        :placeholder="$t('tasks::types.placeholders.translation_key', model.id ? {attribute: model.name} : {}).ucFirst()"
                        :state="states.translation_key"
                    ></translation-input>
                    <div :class="{'d-block': states.translation_key === false}" class="invalid-feedback">
                        {{ errors.translation_key }}
                    </div>
                </div>
                <div class="col-lg-3">

                    <label
                        :class="{'text-danger': states.description === false}"
                        class="form-label"
                        for="description"
                    >{{ $t('tasks::types.columns.description').ucFirst() }}</label
                    >
                    <textarea
                        id="description"
                        v-model="model.description"
                        :class="{'border-danger': states.description === false}"
                        :placeholder="$t('tasks::types.placeholders.description').ucFirst()"
                        class="form-control"
                        rows="3"
                    ></textarea>
                    <div :class="{'d-block': states.description === false}" class="invalid-feedback">
                        {{ errors.description }}
                    </div>
                </div>
                <div class="col-lg-3">
                    <label
                        :class="{'text-danger': states.description_translation_key === false}"
                        class="form-label"
                        for="description_translation_key"
                    >{{ $t('tasks::types.columns.description_translation_key').ucFirst() }}</label
                    >
                    <translation-input
                        v-model="model.description_translation_key"
                        :error="errors.description_translation_key"
                        :placeholder="$t('tasks::types.placeholders.description_translation_key', model.id ? {attribute: model.name} : {}).ucFirst()"
                        :state="states.description_translation_key"
                    ></translation-input>
                    <div :class="{'d-block': states.description_translation_key === false}" class="invalid-feedback">
                        {{ errors.description_translation_key }}
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-lg-2">
                    <label
                        :class="{'text-danger': states.default === false}"
                        class=" form-check-label"
                        for="default-input"
                    >{{ $t('tasks::types.columns.default').ucFirst() }}</label
                    >
                    <div class="form-check form-switch form-switch-md">
                        <input
                            id="default-input"
                            v-model="model.default"
                            :class="{'border-danger': states.default === false}"
                            class="form-check-input"
                            name="default"
                            type="checkbox"
                        />
                    </div>
                    <div :class="{'d-block': states.default === false}" class="invalid-feedback">
                        {{ errors.default }}
                    </div>
                </div>
            </div>

            <div class="row justify-content-end">
                <div class="col-lg-12">
                    <button class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<style scoped>

</style>
