<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ title }}</h4>
                </div>
                <div class="card-body">
                    <task-type-form :task-type="taskType">
                        <template #buttons="{save}">
                            <callback-button
                                class="ms-2"
                                :method="save"
                                :callback="back"
                            ></callback-button>
                        </template>
                    </task-type-form>
                </div>
            </div>
        </div>
    </div>
    <!-- end row -->
</template>

<script>

import {mapGetters} from "vuex";
import CallbackButton from "../../../../components/elements/callback-button.vue";
import TaskTypeForm from "../../../../components/forms/tasks/type-form.vue";

export default {
    components: {TaskTypeForm, CallbackButton},

    computed: {
        ...mapGetters({
            taskType: 'taskType/item'
        }),

        title: function () {
            return this.taskType.id ? this.$tc('tasks::types.type', 2).ucFirst() + ' / ' + (this.taskType.translation_key ? this.$t(this.taskType.translation_key).ucFirst() :this.taskType.name) : this.$t('base.create_new_item', {item: this.$tc('tasks::types.type', 1)}).ucFirst()
        },

        link: function () {
            return {path: this.$t('routes.' + '/master-data/tasks/types')}
        }
    },

    methods: {
        back: function () {
            this.$router.push(this.link)
        },
    },

    watch: {
        title: function (value) {
            this.$store.dispatch('app/title', {key: value})
        },
    },

    mounted() {
        if (this.$route.params.id) {
            this.$store.dispatch('taskType/show', {id: this.$route.params.id})
        }
    },

    unmounted() {
        this.$store.dispatch('taskType/clearItem')
        this.$store.dispatch('app/title', '')
    }

}
</script>

<style scoped>

</style>
